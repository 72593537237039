import { React, Component } from "react";
class MapWidget extends Component {
	constructor() {
		super();
		this.mapLink = "https://iss.artefaktas.workers.dev/";
		this.key = "AIzaSyCm7GmdmfIGISF70Kw1pzk4QYE11xe88AM";
		this.state = {
			longitude: null,
			lattitude: null,
			timestamp: null,
			status: null,
		};
		this.tableStyle = {
			color: "lightgreen",
		};
		this.fetchData = this.fetchData.bind(this);
	}

	fetchData() {
		let mapLink = this.mapLink;
		async function fetchTKSJSON() {
			const response = await fetch(mapLink);
			const jsonData = await response.json();
			return jsonData[0];
		}
		fetchTKSJSON().then((d) => {
			this.setState(
				{
					longitude: d.iss_position.longitude,
					lattitude: d.iss_position.latitude,
					timestamp: d.timestamp,
					status: d.message,
				},
				() => {
					if (this.state.lattitude !== null && this.state.longitude !== null) {
						window.initMap = () => {
							const myLatLng = {
								lat: parseInt(this.state.lattitude),
								lng: parseInt(this.state.longitude),
							};
							const map = new window.google.maps.Map(
								document.getElementById("map"),
								{
									zoom: 4,
									center: myLatLng,
								}
							);
							new window.google.maps.Marker({
								position: myLatLng,
								map,
								title: "ISS MAP",
							});
						};
						if (window.initMap) {
							let selectorId = "gMapsScript";
							if (!document.getElementById(selectorId)) {
								let scG = document.createElement("script");
								scG.setAttribute("id", selectorId);
								scG.src =
									`https://maps.googleapis.com/maps/api/js?key=` +
									this.key +
									`&callback=initMap&libraries=&v=weekly`;
								scG.defer = `defer`;
								document.head.appendChild(scG);
							} else {
								const myLatLng = {
									lat: parseInt(this.state.lattitude),
									lng: parseInt(this.state.longitude),
								};
								const map = new window.google.maps.Map(
									document.getElementById("map"),
									{
										zoom: 4,
										center: myLatLng,
									}
								);
								new window.google.maps.Marker({
									position: myLatLng,
									map,
									title: "ISS MAP",
								});
							}
						}
					}
				}
			);
		});
	}

	componentDidMount() {
		this.fetchData();
		setInterval(() => {
			this.fetchData();
		}, 420000);
	}

	render() {
		return (
			<div className="table-responsive">
				<table className="table table-striped table-dark mt-2">
					<thead>
						<tr>
							<th scope="col">#</th>
							<th>Lattitude</th>
							<th>Longitude</th>
							<th>Time</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody className="tbodyadd">
						<tr>
							<th scope="row">1</th>
							<td>{this.state.lattitude}</td>
							<td>{this.state.longitude}</td>
							<td>{Date(this.state.timestamp)}</td>
							<td style={this.tableStyle}>{this.state.status}</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
}

export default MapWidget;
